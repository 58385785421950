<template>
  <div class="model-warehouse">
    <div v-if="showImageWarehouse">
      <div class="top-bar">
        <div v-if="spaceName" class="space-name">
          {{ spaceName }}
        </div>
        <!-- 命名空间 -->
        <div>
          <el-select v-model="spaceNameId" placeholder="请选择" filterable>
            <el-option
              v-for="item in spaceNameOptions"
              :key="item.projectId"
              :label="item.name"
              :value="item.projectId
              "
            />
          </el-select>
          <el-button type="primary" @click="openSpaceNameDialog">管理空间</el-button>
        </div>
        <el-input v-model="search" placeholder="输入内容查询" @input="searchHandle(search)" />

      </div>
      <div class="main-content">
        <div class="headers">
          <div>
            <el-button type="primary" @click="openUpdaterDialogImageDialog">上传镜像</el-button>
            <el-button type="default" @click="deleteImage">删除镜像</el-button>
          </div>
        </div>
        <div class="content">
          <el-table
            v-loading="isLoading"
            :data="tableData"
            tooltip-effect="dark"
            @selection-change="handleSelectionChange"
            @sort-change="sortChange"
          >
            <el-table-column
              type="selection"
              width="55"
              label="序号"
            />
            <el-table-column
              label="镜像名称"
              prop="name"
              show-overflow-tooltip
              sortable="custom"
            >
              <template #default="scope">
                <span style="color: #165DFF;cursor: pointer;" @click="toImageDetail(scope.row)">{{ scope.row.name }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="公开性"
              prop="name"
              show-overflow-tooltip
            >
              <template #default="scope">
                <span>
                  {{
                    spaceNameOptions.find(item => item.projectId === scope.row.project_id).metadata.publicFlag === 'true' ?'公开':'私有'
                  }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              label="版本数"
              prop="artifact_count"
              show-overflow-tooltip
            />
            <el-table-column
              label="下载数"
              prop="pull_count"
              show-overflow-tooltip
              sortable="custom"
            />
            <el-table-column
              label="创建时间"
              prop="creationTime"
              show-overflow-tooltip
              sortable="custom"
            >
              <template #default="scope">
                {{ rTime(scope.row.creation_time) }}
              </template>
            </el-table-column>
          </el-table>
          <div class="paginations">
            <div class="pagination">
              <div class="total">合计：{{ totalPage }}</div>
              <el-pagination
                v-model:currentPage="pageNum"
                :page-size="pageSize"
                :page-count="pagenumber"
                :page-sizes="pageSizes"
                layout="sizes"
                :total="totalPage"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              />
              <div class="jumper">
                <div>{{ pageNum }}/{{ pagenumber }}</div>
                <div class="jumper-line" />
                <el-pagination
                  v-model:currentPage="pageNum"
                  :page-size="pageSize"
                  :page-count="pagenumber"
                  :page-sizes="pageSizes"
                  background
                  layout="jumper"
                  :total="totalPage"
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                />
              </div>
              <el-pagination
                v-model:currentPage="pageNum"
                :page-size="pageSize"
                :page-count="pagenumber"
                :page-sizes="pageSizes"
                background
                layout="prev, next"
                :total="totalPage"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- 管理命名空间 -->
      <div class="space-name-dialog">
        <el-dialog
          v-model="spaceNameDialogVisible"
          title="命名空间"
          width="1100px"
          :before-close="handleCloseSpaceNameDialog"
          :close-on-click-modal="false"
        >

          <div class="main-content">
            <div class="headers">
              <el-button type="primary" @click="openCreateSpaceNameDialog">创建命名空间</el-button>
              <el-input v-model="spaceNameSearch" placeholder="输入内容查询" @input="spaceNameSearchHandle(spaceNameSearch)" />
            </div>
            <div class="content">
              <el-table
                v-loading="isLoading"
                :data="spaceNameTableData"
                tooltip-effect="dark"
                @sort-change="spaceNameSortChange"
              >
                <el-table-column
                  label="名称"
                  prop="name"
                  show-overflow-tooltip
                  sortable="custom"
                />
                <el-table-column
                  label="用量/总容量"
                  prop="name"
                  show-overflow-tooltip
                >
                  <template #default="scope">
                    {{ usedStorage( scope.row.projectSummaryDTO.quota.used.storage) }} / {{ hardStorage(scope.row.projectSummaryDTO.quota.hard.storage) }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="访问级别"
                  prop="publicFlag"
                  show-overflow-tooltip
                >
                  <template #default="scope">
                    {{ scope.row.metadata.publicFlag === 'true' ?'公有':'私有' }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="镜像数量"
                  prop="repoCount"
                  show-overflow-tooltip
                  align="center"
                />
                <el-table-column
                  label="创建时间"
                  prop="creationTime"
                  show-overflow-tooltip
                  sortable="custom"
                >
                  <template #default="scope">
                    {{ rTime(scope.row.creationTime) || scope.row.creationTime }}
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="100px" align="center">
                  <template #default="scope">
                    <el-button type="text" size="mini" @click="deleteSpaceNameHandle(scope.row)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <!-- <div class="paginations">
              <el-pagination
                v-model:current-page="spaceNamePageNum"
                :page-sizes="spaceNamePageSizes"
                :page-size="spaceNamePageSize"
                layout="total, prev, pager, next, sizes"
                :total="spaceNameTotalPage"
                @size-change="spaceNameHandleSizeChange"
                @current-change="spaceNameHandleCurrentChange"
              />
            </div> -->
              <div class="paginations">
                <div class="pagination">
                  <div class="total">合计：{{ spaceNameTotalPage }}</div>
                  <el-pagination
                    v-model:currentPage="spaceNamePageNum"
                    :page-size="pageSize"
                    :page-count="spaceNamePagenumber"
                    :page-sizes="spaceNamePageSizes"
                    layout="sizes"
                    :total="spaceNameTotalPage"
                    @size-change="spaceNameHandleSizeChange"
                    @current-change="spaceNameHandleCurrentChange"
                  />
                  <div class="jumper">
                    <div>{{ spaceNamePageNum }}/{{ spaceNamePagenumber }}</div>
                    <div class="jumper-line" />
                    <el-pagination
                      v-model:currentPage="spaceNamePageNum"
                      :page-size="pageSize"
                      :page-count="spaceNamePagenumber"
                      :page-sizes="spaceNamePageSizes"
                      background
                      layout="jumper"
                      :total="spaceNameTotalPage"
                      @size-change="spaceNameHandleSizeChange"
                      @current-change="spaceNameHandleCurrentChange"
                    />
                  </div>
                  <el-pagination
                    v-model:currentPage="spaceNamePageNum"
                    :page-size="pageSize"
                    :page-count="spaceNamePagenumber"
                    :page-sizes="spaceNamePageSizes"
                    background
                    layout="prev, next"
                    :total="spaceNameTotalPage"
                    @size-change="spaceNameHandleSizeChange"
                    @current-change="spaceNameHandleCurrentChange"
                  />
                </div>
              </div>
            </div>
          </div>
        </el-dialog>
      </div>
      <!-- 创建命名空间 -->
      <div class="create-space">
        <el-dialog
          v-model="createSpaceNameDialogVisible"
          title="创建命名空间"
          width="650px"
          :before-close="handleCloseCreateSpaceNameDialog"
          :close-on-click-modal="false"
        >
          <el-form ref="createSpaceNameForm" :model="createSpaceNameForm" :rules="createRules" label-width="120px">
            <el-form-item label="命名空间名称" prop="spaceNameName">
              <el-input v-model="createSpaceNameForm.spaceNameName" placeholder="请输入命名空间名称" size="small" />
            </el-form-item>
            <el-form-item label="存储容量" prop="isDelete">
              <el-input v-model="createSpaceNameForm.storageCapacity" disabled />GB
            </el-form-item>
          </el-form>
          <template #footer>
            <el-button @click="handleCloseCreateSpaceNameDialog">取消</el-button>
            <el-button v-loading="submitLoading" type="primary" @click="createSpaceNameHandle">提交</el-button>
          </template>
        </el-dialog>
      </div>
      <!-- 上传镜像 -->
      <el-dialog
        v-model="imageDialogVisible"
        title="上传镜像"
        width="578px"
        :before-close="handleCloseImageDialog"
        :close-on-click-modal="false"
      >
        <template #title>
          <span style="margin-right: 8px;">上传镜像</span>
          <el-tooltip class="item" effect="dark" content="管理员可以上传镜像到公共镜像仓库中" placement="bottom">
            <svg-icon class="icon" icon-class="svg-tip" />
          </el-tooltip>
        </template>
        <div v-loading="submitLoading" class="dialog-image-content">
          <div class="content-card" style="margin-bottom: 30px;">
            <div class="title">登录容器镜像服务</div>
            <div class="card-content">
              <span @click="copyInfo({target:{innerText:`  docker login ${harborUrl} --username ${harborAccount } --password ${harborPassword }`}})">
                docker login {{ harborUrl }} --username {{ harborAccount }} --password
                <span v-show="showPassword">{{ harborPassword }}</span>
                <span v-show="!showPassword">***********</span>
              </span>
              <span class="show-pwd" @click="showPassword = !showPassword">
                <svg-icon :icon-class="!showPassword ? 'eye' : 'eye-open'" />
              </span>
              <button @click="getRandomPassword">生产随机密码</button>
            </div>
          </div>
          <div class="content-card">
            <div class="title">
              推送镜像
            </div>
            <div class="card-content">
              <span @click="copyInfo">
                docker tag 镜像名称:镜像版本 {{ harborUrl }}/{{ spaceName }}/镜像名称:镜像版本
              </span>
              <br>
              <span @click="copyInfo">
                docker push {{ harborUrl }}/{{ spaceName }}/镜像名称:镜像版本
              </span>
            </div>
          </div>
        </div>
      </el-dialog>
      <!-- 修改harbor密码 -->
      <div class="dialog-container">
        <el-dialog
          v-model="passVisible"
          title="修改密码"
          :close-on-click-modal="false"
          :before-close="handleHarborUserInfoClosePsd"
          width="500px"
        >
          <div class="dialog-content">
            <!--基本信息-->
            <div class="base-info">
              <div>
                <el-form ref="passInfoForm" :rules="rules" :model="passInfo" size="small">
                  <div class="dialog-form">
                    <el-form-item prop="newPassword" label="新密码" label-width="7em">
                      <el-input v-model="passInfo.newPassword" show-password :type="newPasswordType" placeholder="请输入新密码" />
                    </el-form-item>
                    <el-form-item prop="checkPass" label="确认密码" label-width="7em">
                      <el-input v-model="passInfo.checkPass" show-password :type="rePasswordType" placeholder="请再次输入新密码" />
                    </el-form-item>
                    <el-form-item prop="msgCode" label="手机验证码" label-width="7em">
                      <el-input v-model="passInfo.msgCode" style="width:50%" placeholder="请输入手机验证码" />
                      <el-button
                        type="primary"
                        :disabled="isDisabledPsd"
                        class="send-msg"
                        @click="sendMsgPsdHandle"
                      >
                        {{ buttonNamePsd }}
                      </el-button>
                    </el-form-item>
                  </div>
                </el-form>
              </div>
            </div>
          </div>
          <template #footer>
            <el-button size="small" @click="handleHarborUserInfoClosePsd">取 消</el-button>
            <el-button :loading="psdConfirm" size="small" type="primary" @click="handlePassInfoCreate">确 定</el-button>
          </template>
        </el-dialog>
      </div>
    </div>
    <div v-else class="warehouse-detail">
      <div class="model-detail">
        <div class="header">
          <span style="cursor: pointer;" @click="toWareHouse">
            <el-icon><ArrowLeft /></el-icon>
            镜像管理
          </span>
        </div>
        <div class="main-content">
          <div class="content">
            <el-table
              v-loading="isLoading"
              :data="detailTableData"
              tooltip-effect="dark"
              :header-row-style="{backgroundColor: '#E7E7E7'}"
            >
              <el-table-column
                label="镜像名称"
                prop="artifacts_name"
                show-overflow-tooltip
              />
              <el-table-column
                label="镜像版本"
                prop="name"
                show-overflow-tooltip
              />
              <!-- <el-table-column
            label="镜像版本编号"
            prop="digest"
            show-overflow-tooltip
          /> -->
              <el-table-column
                label="更新时间"
                prop="push_time"
                show-overflow-tooltip
              />
            </el-table>
            <div class="paginations">
              <el-pagination
                v-model:current-page="detailPageNum"
                :page-sizes="detailPageSizes"
                :page-size="detailPageSize"
                layout="total, prev, pager, next, sizes"
                :total="detailTotalPage"
                @size-change="detailHandleSizeChange"
                @current-change="detailHandleCurrentChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { generateRandomPassword, getAllNamespaces, getPagedNamespaces, deleteNamespace, createNamespace, getImageListCount, getNamespaceCount,
  getImageList, getHarborUserInfo, getAllImageByNamespace, updateHarborUserPassword, getPhoneVerificationCode, deleteImage, getArtifactVersions } from '@/api/ai'
import moment from 'moment'
import { ElMessageBox } from 'element-plus'
const validatePassword = (rule, value, callback) => {
  const reg = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,20}$/
  if (value === '') {
    callback(new Error('请输入密码'))
  } else if (value.length < 8 || value.length > 20) {
    callback(new Error('密码长度必须在8-20位之间'))
  } else if (!reg.test(value)) {
    callback(new Error('至少包含至少一个大写字符，一个小写字符和一个数字'))
  } else {
    callback()
  }
}

export default {
  beforeRouteLeave(to, from, next) {
    sessionStorage.setItem(
      'CountDownPhoneInfo',
      JSON.stringify({
        CountDownPsd: this.CountDownPsd,
        isDisabledPsd: this.isDisabledPsd
      })
    )
    next()
  },
  data() {
    const checkPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.passInfo.newPassword) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      isLoading: false,
      search: '',
      timer: null,
      pageSize: 10,
      pageNum: 1,
      totalPage: 0,
      pageSizes: [5, 10, 20, 30],
      sortBy: '',
      orderBy: '',
      pagenumber: 0,
      tableData: [],
      multipleSelection: [],
      spaceNameOptions: [],
      spaceNameId: '',
      currentRow: null,
      /** 命名空间 */
      submitLoading: false,
      spaceNameDialogVisible: false,
      spaceNameSearch: '',
      spaceNameTableData: [],
      spaceNamePageNum: 1,
      spaceNamePageSize: 10,
      spaceNameTotalPage: 0,
      spaceNamePagenumber: 0,
      spaceNamePageSizes: [5, 10, 20, 30],
      spaceNameSortBy: '',
      deleteSpaceNameDialogVisible: false,
      deleteSpaceNameForm: {
        spaceNameName: '',
        isDelete: false,
        projectId: ''
      },
      validatorName: '',
      rules: {
        spaceNameName: [
          { required: true, message: '请输入命名空间名称', trigger: 'blur' },
          { validator: (rule, value, callback) => {
            if (!value) {
              callback(new Error('请输入命名空间名称'))
            } else if (value !== this.validatorName) {
              callback(new Error('请输入对应的命名空间名称'))
            } else {
              callback()
            }
          }, trigger: 'blur' }
        ],
        isDelete: [
          {
            required: true, message: '请选择是否强制删除', trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error('请选择是否强制删除'))
              } else {
                callback()
              }
            }
          }
        ],
        msgCode: [
          { required: true, trigger: 'blur', message: '请输入手机收到的验证码' }
        ],
        newPassword: [
          { trigger: 'blur', validator: validatePassword },
          { required: true, message: '请输入新密码', trigger: 'blur' }
        ],
        checkPass: [
          { validator: checkPass, trigger: 'blur' },
          { required: true, message: '请再次输入密码', trigger: 'blur' }
        ]

      },
      createRules: {
        spaceNameName: [
          { required: true, message: '请输入命名空间名称', trigger: 'blur' },
          { validator: (rule, value, callback) => {
            const reg = /^[a-z0-9-_]{1,50}$/
            if (!value) {
              callback(new Error('请输入命名空间名称'))
            } else if (!reg.test(value)) {
              callback(new Error('命名空间名称只能包含小写字母、数字、-_并且1到50位以内'))
            } else {
              callback()
            }
          }, trigger: 'blur' }
        ],
        storageCapacity: [
          { required: true, message: '请输入存储容量', trigger: 'blur' }
        ]
      },
      imageDialogVisible: false,
      harborAccount: '',
      harborPassword: '',
      harborUrl: '',
      passVisible: false,
      passInfo: {
        newPassword: '',
        checkPass: '',
        msgCode: ''
      },
      isDisabledPsd: false,
      buttonNamePsd: '获取验证码',
      rePasswordType: 'password',
      newPasswordType: 'password',
      psdConfirm: false,
      CountDownPsd: 60,
      createSpaceNameForm: {
        spaceNameName: '',
        storageCapacity: '10'
      },
      createSpaceNameDialogVisible: false,
      isDeleteCurrentSpace: false,
      showImageWarehouse: true,
      // 详情
      detailPageNum: 1,
      detailPageSize: 10,
      detailTotalPage: 0,
      detailPageSizes: [5, 10, 20, 30],
      detailTableData: [],
      showPassword: false
    }
  },
  computed: {
    spaceName() {
      return this.spaceNameOptions.find(item => item.projectId === this.spaceNameId)?.name
    }
  },
  watch: {
    spaceNameId: {
      handler(val) {
        this.getData()
      }
    }
  },
  created() {
    getAllNamespaces().then(res => {
      this.spaceNameOptions = res.data
      if (this.spaceNameOptions.length > 0) {
        this.spaceNameId = this.spaceNameOptions[0].projectId || this.$route.query.projectId
      }
    })
  },
  mounted() {
    if (sessionStorage.getItem('CountDownPhoneInfo')) {
      this.CountDownPhoneInfo = JSON.parse(
        sessionStorage.getItem('CountDownPhoneInfo')
      ).CountDownPsd
      this.isDisabledPsd = JSON.parse(
        sessionStorage.getItem('CountDownPhoneInfo')
      ).isDisabledPsd
    }
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem(
        'CountDownPhoneInfo',
        JSON.stringify({
          CountDownPsd: this.CountDownPsd,
          isDisabledPsd: this.isDisabledPsd
        })
      )
    })
    if (this.isDisabledPsd) {
      this.timerPhone = setInterval(() => {
        this.CountDownPsd--
        this.buttonNamePsd = this.CountDownPsd + '秒后重试'
        if (this.CountDownPsd <= 0) {
          clearInterval(this.timerPhone)
          this.buttonNamePsd = '获取验证码'
          this.CountDownPsd = 60
          this.isDisabledPsd = false
        }
      }, 1000)
    }
  },
  methods: {
    getRandomPassword() {
      // 调接口生成
      this.submitLoading = true
      generateRandomPassword().then((res) => {
        if (res.meta.status === 200) {
          this.harborPassword = res.data
          this.showPassword = true
        }
      }).finally(() => {
        this.submitLoading = false
      })
    },
    rTime(date) {
      if (!date) {
        return '-'
      } else {
        return moment(date).format('YYYY-MM-DD HH:mm:ss')
      }
    },
    usedStorage(val) {
      if (val === 0) {
        return 0 + 'GB'
      }
      return (val / 1024 / 1024 / 1024).toFixed(2) + 'GB'
    },
    hardStorage(val) {
      if (val === -1) {
        return '无限制'
      }
      return (val / 1024 / 1024 / 1024).toFixed(0) + 'GB'
    },
    // 跳转镜像详情
    toImageDetail(row) {
      // this.$router.push({
      //   path: '/model/detail?name=' + row.name + '&projectId=' + this.spaceNameId
      // })
      this.showImageWarehouse = false
      this.currentRow = row
      this.getArtifactVersions()
    },
    toWareHouse() {
      this.showImageWarehouse = true
    },
    // 根据命名空间查询镜像列表
    getData() {
      if (!this.spaceNameId) {
        return
      }
      this.isLoading = true
      getImageList(this.pageNum, this.pageSize, this.spaceName, this.spaceNameId, this.search, this.sortBy).then(res => {
        this.tableData = res.data
        this.isLoading = false
      })

      if (this.search) {
        getAllImageByNamespace(this.spaceName).then(res => {
          const allImages = res.data
          const filteredImages = allImages.filter(item => (this.spaceName + '/' + item.name).includes(this.search))
          this.totalPage = filteredImages.length
          this.pagenumber = Math.ceil(this.totalPage / this.pageSize)
        })
      } else {
        getImageListCount(this.spaceNameId).then(res => {
          this.totalPage = Number(res.data.repo_count)
          this.pagenumber = Math.ceil(this.totalPage / this.pageSize)
        })
      }
    },
    // 模糊查询
    searchHandle(val) {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.pageNum = 1
        this.search = val
        this.$nextTick(() => {
          this.getData()
        })
      }, 500)
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.pageNum = 1
      this.getData()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getData()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    sortChange(val) {
      this.pageNum = 1
      if (val.order === 'ascending') {
        this.sortBy = val.prop
      } else {
        this.sortBy = '-' + val.prop
      }
      this.getData()
    },
    /** 命名空间 */
    // 查询全部命名空间
    getAllNamespaces() {
      getAllNamespaces().then(res => {
        this.spaceNameOptions = res.data
        if (res.data.length === 0) {
          this.spaceNameId = ''
        } else {
          this.spaceNameId = this.spaceNameOptions[0].projectId
        }
      })
    },
    // 分页查询命名空间
    getPageNamespaces() {
      this.isLoading = true
      getPagedNamespaces(this.spaceNamePageNum, this.spaceNamePageSize, this.spaceNameSortBy, this.spaceNameSearch).then(res => {
        this.isLoading = false
        this.spaceNameTableData = res.data
        this.spaceNameTotalPage = this.spaceNameTableData.length
      })

      if (this.spaceNameSearch) {
        const filtered = this.spaceNameOptions.filter(item => item.name.includes(this.spaceNameSearch))
        this.spaceNameTotalPage = filtered.length
        this.spaceNamePagenumber = Math.ceil(this.spaceNameTotalPage / this.spaceNamePageSize)
      } else {
        getNamespaceCount().then(res => {
          this.spaceNameTotalPage = Number(res.data.private_project_count)
          this.spaceNamePagenumber = Math.ceil(this.spaceNameTotalPage / this.spaceNamePageSize)
        })
      }
    },
    // 模糊查询
    spaceNameSearchHandle(val) {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.spaceNamePageNum = 1
        this.spaceNameSearch = val
        this.$nextTick(() => {
          this.getPageNamespaces()
        })
      }, 500)
    },
    // 排序
    spaceNameSortChange(val) {
      this.spaceNamePageNum = 1
      this.spaceNameSortBy = val.prop
      if (val.order === 'ascending') {
        this.spaceNameSortBy = val.prop
      } else {
        this.spaceNameSortBy = '-' + val.prop
      }
      this.getPageNamespaces()
    },
    // 分页
    spaceNameHandleSizeChange(val) {
      this.spaceNamePageSize = val
      this.spaceNamePageNum = 1
      this.getPageNamespaces()
    },
    spaceNameHandleCurrentChange(val) {
      this.spaceNamePageNum = val
      this.getPageNamespaces()
    },
    // 打开命名空间管理弹窗
    openSpaceNameDialog() {
      this.spaceNameDialogVisible = true
      this.getPageNamespaces()
    },
    // 关闭命名空间管理弹窗
    handleCloseSpaceNameDialog() {
      this.spaceNameDialogVisible = false
      this.spaceNameSearch = ''
      this.spaceNamePageNum = 1
      this.spaceNamePageSize = 10
      this.spaceNameSortBy = ''
      getAllNamespaces().then(res => {
        this.spaceNameOptions = res.data
        if (this.isDeleteCurrentSpace) {
          if (this.spaceNameOptions.length === 0) {
            this.spaceNameId = ''
          } else {
            this.spaceNameId = this.spaceNameOptions[0].projectId
          }
          this.isDeleteCurrentSpace = false
        }
      })
    },
    // 删除命名空间
    deleteSpaceNameHandle(row) {
      ElMessageBox.confirm('确认要将该命名空间删除吗？', '删除提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        icon: ''
      }).then(() => {
        deleteNamespace(row.projectId).then((res) => {
          if (res.meta.status === 200) {
            if (row.projectId === this.spaceNameId) {
              this.isDeleteCurrentSpace = true
            }
            this.$message.success('删除成功')
            const totalPage = Math.ceil((this.spaceNameTotalPage - 1) / this.spaceNamePageSize)
            this.spaceNamePageNum = this.spaceNamePageNum > totalPage ? totalPage : this.spaceNamePageNum
            this.spaceNamePageNum = this.spaceNamePageNum < 1 ? 1 : this.spaceNamePageNum
            this.getPageNamespaces()
          } else {
            this.$message.error(res.meta.message)
          }
        })
      }).catch(() => {
        this.$message.info('已取消删除')
      })
    },
    /** 镜像 */
    // 打开上传镜像弹窗
    openUpdaterDialogImageDialog() {
      if (this.spaceNameOptions.length === 0) {
        this.$message.error('请先创建命名空间')
        return
      }
      this.imageDialogVisible = true
      this.submitLoading = true
      getHarborUserInfo().then(res => {
        if (res.meta.status === 200) {
          this.harborAccount = res.data.harborAccount
          this.harborPassword = res.data.harborPassword
          this.harborUrl = res.data.harborAddress
        } else {
          this.$message.error(res.meta.message)
        }
      }).finally(() => {
        this.submitLoading = false
      })
    },
    // 关闭上传镜像弹窗
    handleCloseImageDialog() {
      this.imageDialogVisible = false
      this.showPassword = false
    },
    // 删除镜像
    deleteImage() {
      if (this.multipleSelection.length === 0) {
        this.$message.warning('请选择要删除的镜像')
        return
      }
      ElMessageBox.confirm('确认要删除选中得镜像吗？', '删除提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        icon: ''
      }).then(() => {
        this.concurRequest(this.multipleSelection.map(row => row.name), 5).then(() => {
          this.$message.success('删除成功')
          const totalPage = Math.ceil((this.totalPage - 1) / this.pageSize)
          this.pageNum = this.pageNum > totalPage ? totalPage : this.pageNum
          this.pageNum = this.pageNum < 1 ? 1 : this.pageNum
          this.getData()
        })
      }).catch(() => {
        this.$message.info('已取消删除')
      })
    },
    // 递归请求
    concurRequest(names, maxNum) {
      return new Promise(resolve => {
        if (names.length === 0) {
          resolve([])
          return
        }
        const results = []
        let index = 0
        async function request() {
          if (index >= names.length) {
            return
          }
          const i = index
          const name = names[index]
          index++
          const resp = await deleteImage(name)
          try {
            results[i] = resp
          } catch {
            results[i] = resp
          } finally {
            if (results.length === names.length) {
              resolve(results)
            }
            request()
          }
        }
        for (let index = 0; index < maxNum; index++) {
          request()
        }
      })
    },
    /** 修改harbor密码 */
    // 打开修改harbor密码弹窗
    openHarborUserInfoDialog() {
      this.passVisible = true
    },
    handleHarborUserInfoClosePsd() {
      this.passVisible = false
      this.$refs.passInfoForm.resetFields()
      this.passInfo = {
        newPassword: '',
        checkPass: '',
        msgCode: ''
      }
    },
    handlePassInfoCreate() {
      this.$refs.passInfoForm.validate((valid) => {
        if (valid) {
          const data = {
            smsCode: this.passInfo.msgCode,
            newPassword: this.passInfo.newPassword
          }
          updateHarborUserPassword(data).then((res) => {
            this.$message.success('修改成功')
            this.passVisible = false
            this.submitLoading = true
            getHarborUserInfo().then(res => {
              if (res.meta.status === 200) {
                this.harborAccount = res.data.harborAccount
                this.harborPassword = res.data.harborPassword
                this.harborUrl = res.data.harborAddress
              } else {
                this.$message.error(res.meta.message)
              }
            }).finally(() => {
              this.submitLoading = false
            })
            this.handleHarborUserInfoClosePsd()
          })
        }
      })
    },
    sendMsgPsdHandle() {
      // 先把按钮禁止，并进行倒计时，防止网速慢的用户重复点击
      this.isDisabledPsd = true
      this.timerPsd = setInterval(() => {
        this.CountDownPsd--
        this.buttonNamePsd = this.CountDownPsd + '秒后重试'
        if (this.CountDownPsd <= 0) {
          clearInterval(this.timerPsd)
          this.buttonNamePsd = '获取验证码'
          this.CountDownPsd = 60
          this.isDisabledPsd = false
        }
      }, 1000)
      // 操作验证发送API BLOCK
      getPhoneVerificationCode().then((res) => {
        if (res.meta.status === 200) {
          this.$message.success('发送成功')
        } else {
          this.$message.error(res.meta.msg)
        }
      }).catch(() => {
        // 若接口请求错误，把倒计时关了，按钮恢复可点击状态
        clearInterval(this.timerPsd)
        this.buttonNamePsd = '获取验证码'
        this.CountDownPsd = 60
        this.isDisabledPsd = false
      })
    },
    showNewPwd() {
      if (this.newPasswordType === 'password') {
        this.newPasswordType = 'text'
      } else {
        this.newPasswordType = 'password'
      }
    },
    showRePwd() {
      if (this.rePasswordType === 'password') {
        this.rePasswordType = 'text'
      } else {
        this.rePasswordType = 'password'
      }
    },
    openCreateSpaceNameDialog() {
      this.createSpaceNameDialogVisible = true
    },
    handleCloseCreateSpaceNameDialog() {
      this.createSpaceNameDialogVisible = false
      this.$refs.createSpaceNameForm.resetFields()
      this.createSpaceNameForm = {
        spaceNameName: '',
        storageCapacity: '10'
      }
    },
    createSpaceNameHandle() {
      this.$refs.createSpaceNameForm.validate((valid) => {
        if (valid) {
          this.submitLoading = true
          const data = {
            projectName: this.createSpaceNameForm.spaceNameName
          }
          createNamespace(data).then(() => {
            this.handleCloseCreateSpaceNameDialog()
            this.$message.success('创建成功')
            this.getPageNamespaces()
          }).finally(() => {
            this.submitLoading = false
          })
        }
      })
    },
    getArtifactVersions() {
      getArtifactVersions(this.detailPageNum, this.detailPageSize, this.currentRow.name).then(res => {
        this.detailTableData = res.data
        this.detailTotalPage = this.currentRow.artifact_count
      })
    },
    detailHandleSizeChange(val) {
      this.detailPageSize = val
      this.detailPageNum = 1
      this.getArtifactVersions()
    },
    detailHandleCurrentChange(val) {
      this.detailPageNum = val
      this.getArtifactVersions()
    },
    copyInfo(e) {
      const innerText = e.target.innerText
      const input = document.createElement('input')
      input.value = innerText
      document.body.appendChild(input)
      input.select()
      document.execCommand('copy')
      document.body.removeChild(input)
      this.$message.success('复制成功 ' + innerText)
    }
  }
}
</script>

<style scoped lang="less">
.model-warehouse {
  background-color: #fff;
  padding: 16px;
  padding-top: 30px;
  height: 100%;
  min-width: 900px;
  .show-pwd {
      font-size: 16px;
      cursor: pointer;
      user-select: none;
      margin-left: 8px;
    }
  ::v-deep thead{
    th{
      background-color: #f5f7fa !important;
    }
  }
  .warehouse-detail{
    .header{
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 16px;
      color: #165DFF;
      line-height: 24px;
      // margin-bottom: 16px;
      .el-icon{
        font-size: 16px;
        color: #165DFF;
        // line-height: 19px;
        transform: translateY(2px);
      }
    }

  }
  ::v-deep .send-msg{
    padding-top:15px;
    padding-bottom: 15px;
  }
  .create-space{
    .el-input{
      width: 85%;
      margin-right: 8px;
    }
    ::v-deep .el-button--primary {
      color: #fff !important;
    }
  }
  ::v-deep .icon{
    background-image: none !important;
    font-size: 11px;
  }
  .headers {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    ::v-deep .el-button--primary{
      color: #fff !important;
    }
    ::v-deep .el-button--default {
      border: 1px solid #437AEC !important;
    }
    .el-input {
      width: 290px !important;
      ::v-deep .el-input__inner {
        // border-radius: 24px;
        line-height: 40px;
        height: 40px;
        background-color: #fff;
      }
      ::v-deep .el-input__suffix {
        top: -3px;
      }
    }
  }
  .main-content{
    background-color: #fff;
    padding: 16px 20px;
  }
  .top-bar {
    display: flex;
    justify-content: space-between;
    background-color: #f5f7fa;
    border: 1px solid #CFD3E3;
    border-radius: 6px;
    height: 88px;
    margin-bottom: 16px;
    position: relative;
    padding:32px 24px 16px 24px;
    .el-input{
      width: 360px;
    }
    ::v-deep .el-button--primary {
      color: #fff !important;
    }
    .el-select{
      width: 280px;
      margin-right: 16px;
    }
    .space-name{
      position: absolute;
      top: -15px;
      left: 26px;
      min-width: 94px;
      height: 30px;
      background: #FFFFFF;
      border-radius: 0px 0px 0px 0px;
      border: 1px solid #CFD3E3;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 14px;
      color: #303133;
      line-height: 30px;
      text-align: center;
    }
  }
  .dialog-image-content{
    .title{
      top: -14px;
      left: 26px;
      position: absolute;
      width: 160px;
      background: #FFFFFF;
      border: 1px solid #CFD3E3;
      padding: 3px 0;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 14px;
      color: #303133;
      line-height: 24px;
      text-align: center;
    }
    .content-card{
      position: relative;
      background: #F5F5F5;
      border-radius: 6px 6px 6px 6px;
      border: 1px solid #E7E7E7;
      padding: 32px 26px 16px 26px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #303133;
      line-height: 22px;
      .card-content{
        background-color: #fff;
        border-radius: 4px 4px 4px 4px;
        padding: 20px 16px;
        position: relative;
        span{
          cursor: pointer;
        }
        button{
          position: absolute;
          right: 16px;
          bottom: 10px;
          border: 0;
				  outline: none;
					background-color: #EFF5FF;
          color: #165DFF;
          padding: 2px 12px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
